import React from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
// import 'bootstrap/dist/css/bootstrap.min.css'

import router from './Routes'
import './index.css'
import { GlobalDebug } from './utils/remove-console'

/**
 * @REMOVE_CONSOLES
 * // remove the working of console logs
 * // remove any accidental use of console logs
 */

if (
  process.env.NODE_ENV === 'production' ||
  process.env.REACT_APP_ENV === 'STAGING'
) {
  GlobalDebug(false)
}

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
)
