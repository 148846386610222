import Layout from './containers/Layout'
// import { Helmet } from 'react-helmet'
import { Helmet, HelmetProvider } from 'react-helmet-async'

const App = () => {
  const head = () => (
    <Helmet>
      <meta charSet='utf-8' />      
      <title>Salva's Spot</title>
      <link rel='canonical' href='https://salvatorevivolo.info' />
    </Helmet>
  )
  return (
    <HelmetProvider>
      <Layout>
        {head()}
        <h1 className='p-5 text-center'>
          MERN Authentication Boilerplate
        </h1>
        <h2>Salva's Spot</h2>
        <hr />
        <p className='lead'>
          MERN Application boilerplate with account activation, forgot and
          reset password, login with Facebook, and protected routes.
        </p>
      </Layout>
    </HelmetProvider>
  )
}

export default App
